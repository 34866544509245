import {
    getUserSubscriptionType,
    getUserSubscriptionStatus
} from '../tools/utilities';

document.addEventListener('PelcroSubscriptionCreate', (response, e) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'subscribedStatus': getUserSubscriptionStatus(),
        'subscriberType': getUserSubscriptionType(), // will be 'Digital', 'Bundle' or 'NoSub'
    });

    if (typeof fbq == 'function' && response?.detail?.data?.subscriptions.length > 0) {
        fbq('track', 'Subscribe', {
            currency: response.detail.data.subscriptions[response.detail.data.subscriptions.length - 1].plan.currency.toUpperCase(),
            value: value,
            predicted_ltv: value * response.detail.data.subscriptions[response.detail.data.subscriptions.length - 1].plan.interval_count,
            contents: [
                {
                    id: response.detail.data.subscriptions[response.detail.data.subscriptions.length - 1].plan.id,
                    quantity: 1
                }
            ],
            content_ids: [
                response.detail.data.subscriptions[response.detail.data.subscriptions.length - 1].plan.id
            ],
            content_type: 'product'
        });
    }
});

document.addEventListener('PelcroOrderCreated', (response, e) => {
    if (typeof fbq == 'function' && response?.detail) {
        fbq('track', 'Purchase', {
            currency: response.detail.currency.toUpperCase(),
            value: response.detail.amount > 0
                ? response.detail.amount / 100
                : 0, // Stripe doesn't store prices as decimals so need to divide by 100 e.g. 19.99 is stored as 1999
            contents: response.detail.items.map(
                item => {
                    return {
                        id: item.product_sku_id,
                        quantity: item.quantity
                    };
                }
            ),
            content_ids: response.detail.items.map(
                item => item.product_sku_id
            ),
            content_type: 'product'
        });
    }
});

document.addEventListener('PelcroElementsCartItemAdded', (response, e) => {
    if (typeof fbq == 'function' && response?.detail) {
        fbq('track', 'AddToCart', {
            currency: response.detail.currency.toUpperCase(),
            value: response.detail.price > 0
                ? response.detail.price / 100
                : 0, // Stripe doesn't store prices as decimals so need to divide by 100 e.g. 19.99 is stored as 1999
            contents: [
                {
                    id: response.detail.product_id,
                    quantity: 1
                }
            ],
            content_ids: [
                response.detail.product_id
            ],
            content_name: response.detail.name,
            content_type: 'product'
        });
    }
});

document.addEventListener('PelcroPaywallDisplayed', () => {
    const paywallType = pelcro_details && 'paywall_type' in pelcro_details && pelcro_details.paywall_type != ''
        ? pelcro_details.paywall_type
        : 'none';
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'content_paywall_blocked',
        contentRestricted: 'true',
        paywallBannerType: `${paywallType}`,
    });
});

document.addEventListener('PelcroUserRegister', (response, e) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'Registered',
        loggedInStatus: 'true',
        loggedInUserID: `${response?.detail?.data?.id}`,
        subscribedStatus: getUserSubscriptionStatus(),
        subscriberType: getUserSubscriptionType(), // will be 'Digital', 'Bundle' or 'NoSub'
    });

    if (typeof fbq == 'function' && response?.detail?.data) {
        fbq('track', 'CompleteRegistration', {
            currency: response.detail.data.currency.toUpperCase(),
            value: 0,
            status: true
        });
    }
});

document.addEventListener('PelcroUserLoggedIn', (response, e) => {
    if (response?.detail?.data?.id) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'Logged in',
            loggedInStatus: 'true',
            loggedInUserID: `${response?.detail?.data?.id}`,
            subscribedStatus: getUserSubscriptionStatus(),
            subscriberType: getUserSubscriptionType(), // will be 'Digital', 'Bundle' or 'NoSub'
        });
    }
});

document.addEventListener('PelcroUserLogout', () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'Logged out',
        loggedInStatus: 'false',
        loggedInUserID: '',
        subscribedStatus: 'false',
        subscriberType: 'NoSub',
    });
});
